import React, { Component } from "react";

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav
            href="#navbar"
            className="js-colorlib-nav-toggle colorlib-nav-toggle"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <i />
          </nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
              <div
                className="author-img"
                style={{
                  backgroundImage:
                    "url(https://media.licdn.com/dms/image/v2/D5603AQGQRea2_yQvDA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1699415644502?e=1730937600&v=beta&t=4JTp5l04MwMHoQ9oowVPC8vzgtnTNQhAZ03eVtf3iDY",
                }}
              />
              <h1 id="colorlib-logo">
                <a href="index.html">Jimmy Vasquez</a>
              </h1>
              {/* <span className="email"><i className="icon-mail"></i> cjvasquez_98@hotmail.com</span> */}
              <span className="email">
                |Software Engineer<span role="img"> at Walmart CAM📱 |</span>{" "}
                <br /> |Editor on Nerd For Tech <span role="img">👔|</span>{" "}
                <br /> |Geek ❤| Cinemaker{" "}
                <span role="img">
                  📹 <br />{" "}
                </span>
                |Mobile, Web and Web3 Developer <span role="img">🤖 </span>{" "}
              </span>
              <div></div>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active">
                    <a href="#home" data-nav-section="home">
                      Introduction
                    </a>
                  </li>
                  <li>
                    <a href="#about" data-nav-section="about">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#timeline" data-nav-section="timeline">
                      Tech Career
                    </a>
                  </li>
                  <li>
                    <a href="#" data-nav-section="" disabled>
                      Filming Career (Next coming 👀)
                    </a>
                  </li>
                  <li>
                    <a href="#projects" data-nav-section="projects">
                      Tech Projects
                    </a>
                  </li>
                  <li>
                    <a href="#blog" data-nav-section="blog">
                      Personal Blog
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/JimxDxDhd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-facebook2" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/JimmyVazzz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-twitter2" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/jimmyvazzz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/jimmy-vazz/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-linkedin2" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/JimmyVazz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-github"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://jimmyvazz.medium.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fab fa-medium fa-2x"></i>
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://vigsoft.tech/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fas fa-briefcase fa-2x"></i>
                  </a>
                </li> */}
              </ul>
            </nav>
            <div className="colorlib-footer">
              <p>
                <small>
                  Made with <i className="icon-heart" aria-hidden="true" /> and{" "}
                  <i className="icon-beer" aria-hidden="true"></i>
                  <br></br>
                  Thanks{" "}
                  <a
                    href="https://colorlib.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Colorlib
                  </a>{" "}
                  for inspiration
                </small>
              </p>
              <p>
                <small>¡Just keep doing!</small>
              </p>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
